import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { EpochIncome } from 'data/income';
import { formatLamports } from 'helpers/utils';

export const createEpochIncomeTableColumns = (
  clusterData: EpochIncome[]
): ColumnDef<EpochIncome>[] => {
  const columns: ColumnDef<EpochIncome>[] = [
    {
      accessorKey: 'epoch',
      header: 'Epoch',
      meta: {
        cellProps: { className: 'text-body' }
      }
    }
  ];

  if (clusterData.length > 0) {
    columns.push({
      accessorFn: row => {
        let clusterStake = clusterData.find(value => value.epoch === row.epoch)
          ?.stake;
        if (clusterStake === undefined) {
          return 'NA';
        }
        let stakePct = row.stake / clusterStake;
        let expectedBlocks = stakePct * 432_000;
        return `${expectedBlocks.toFixed(2)} (${(100 * stakePct).toFixed(
          4
        )}% stake)`;
      },
      header: 'Expected Blocks',
      meta: {
        cellProps: { className: 'text-body' }
      }
    });
  }

  const otherColumns: ColumnDef<EpochIncome>[] = [
    {
      accessorFn: row => {
        let slots = row.skippedSlots + row.confirmedSlots;
        let pct = slots > 0 ? (100 * row.skippedSlots) / slots : 0;
        return `${pct.toFixed(2)}% (${row.skippedSlots}/${slots})`;
      },
      header: 'Skip Rate',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorFn: row => {
        const validatorFees =
          row.medianIncome.priorityFees + row.medianIncome.baseFees;
        let clusterRecord = clusterData.find(
          value => value.epoch === row.epoch
        );
        if (clusterRecord === undefined) {
          return formatLamports(validatorFees);
        }

        const clusterFees =
          clusterRecord.medianIncome.priorityFees +
          clusterRecord.medianIncome.baseFees;
        return `${formatLamports(validatorFees)} (${(
          (validatorFees / clusterFees) *
          100
        ).toFixed(2)}% of cluster median)`;
      },
      header: 'Median Block Fees',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorFn: row =>
        formatLamports(row.totalIncome.priorityFees + row.totalIncome.baseFees),
      header: 'Total Block Fees',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorFn: row => {
        const validatorTips = row.medianIncome.mevTips;
        let clusterRecord = clusterData.find(
          value => value.epoch === row.epoch
        );
        if (clusterRecord === undefined) {
          return formatLamports(validatorTips);
        }

        const clusterTips = clusterRecord.medianIncome.mevTips;
        return `${formatLamports(validatorTips)} (${(
          (validatorTips / clusterTips) *
          100
        ).toFixed(2)}% of cluster median)`;
      },
      header: 'Median Block Tips',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorFn: row => formatLamports(row.totalIncome.mevTips),
      header: 'Total MEV Tips',
      meta: {
        cellProps: { className: 'text-body' }
      }
    }
  ];

  return [...columns, ...otherColumns];
};

const EpochIncomeTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default EpochIncomeTable;
