import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { formatLamports } from 'helpers/utils';
import { EpochIncomeLeaderboardRecord } from 'providers/VortexDataProvider';

export const createIncomeLeaderboardTableColumns = (
  clusterRecord: EpochIncomeLeaderboardRecord | undefined
): ColumnDef<EpochIncomeLeaderboardRecord>[] => {
  return [
    {
      accessorFn: (_row, index) => {
        return index + 1;
      },
      header: 'Income Ranking',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'nodeName',
      header: 'Validator',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'nodeAddress',
      header: 'Validator Address',
      cell: ({ cell, row }) => {
        return (
          <a
            href={`/income/${row.original.nodeAddress}`}
            target="_blank"
            rel="noreferrer"
          >
            {row.original.nodeAddress}
          </a>
        );
      },
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'confirmedSlots',
      header: 'Produced Blocks',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorFn: row => {
        const validatorFees =
          row.medianIncome.priorityFees + row.medianIncome.baseFees;
        if (clusterRecord === undefined) {
          return formatLamports(validatorFees);
        }

        const clusterFees =
          clusterRecord.medianIncome.priorityFees +
          clusterRecord.medianIncome.baseFees;
        return `${formatLamports(validatorFees)} (${(
          (validatorFees / clusterFees) *
          100
        ).toFixed(2)}% of cluster median)`;
      },
      header: 'Median Block Fees',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorFn: row => {
        const validatorTips = row.medianIncome.mevTips;
        if (clusterRecord === undefined) {
          return formatLamports(validatorTips);
        }

        const clusterTips = clusterRecord.medianIncome.mevTips;
        return `${formatLamports(validatorTips)} (${(
          (validatorTips / clusterTips) *
          100
        ).toFixed(2)}% of cluster median)`;
      },
      header: 'Median Block Tips',
      meta: {
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorFn: row => {
        const validatorIncome =
          row.medianIncome.baseFees +
          row.medianIncome.priorityFees +
          row.medianIncome.mevTips;
        if (clusterRecord === undefined) {
          return formatLamports(validatorIncome);
        }

        const clusterIncome =
          clusterRecord.medianIncome.baseFees +
          clusterRecord.medianIncome.priorityFees +
          clusterRecord.medianIncome.mevTips;
        return `${formatLamports(validatorIncome)} (${(
          (validatorIncome / clusterIncome) *
          100
        ).toFixed(2)}% of cluster median)`;
      },
      header: 'Median Block Income',
      meta: {
        cellProps: { className: 'text-body' }
      }
    }
  ];
};

const EpochLeaderboardTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default EpochLeaderboardTable;
