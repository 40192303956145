import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import {
  ChangeEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback
} from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { voteRecordBreadcrumbItems } from 'data/voteRecord';
import {
  EpochVotingLeaderboard,
  VortexDataContext
} from 'providers/VortexDataProvider';
import EpochVoteRecordTable, {
  createEpochVoteRecordTableColumns
} from 'components/tables/EpochVoteRecordTable';
import EpochVotesChart from 'components/charts/e-charts/EpochVotesChart';
import { useParams, useNavigate } from 'react-router-dom';
import VoteLatencyOverview from 'components/VoteQualityOverview';
import EpochVoteBucketRecordTable, {
  createEpochVoteBucketRecordTableColumns
} from 'components/tables/EpochVoteBucketRecordTable';
import EpochVoteBucketChart from 'components/charts/e-charts/EpochVoteBucketChart';

const IDEAL_RECORD_KEY = '11111111111111111111111111111111';

function splitArrayIntoChunks<T>(array: T[], chunkSize: number): T[][] {
  const result: T[][] = [];

  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }

  return result;
}

interface SelectedEpoch {
  current: boolean;
  epoch?: number;
}

const VoteHistory = () => {
  const navigate = useNavigate();
  const { voteAddress, epoch, bucket } = useParams();
  const context = useContext(VortexDataContext);
  const initialSelectedEpoch: SelectedEpoch =
    epoch !== undefined && !Number.isNaN(parseInt(epoch))
      ? { epoch: parseInt(epoch), current: false }
      : {
          current: true
        };
  const [selectedEpoch, setSelectedEpoch] =
    useState<SelectedEpoch>(initialSelectedEpoch);
  const [showAllSlots, setShowAllSlots] = useState<boolean>(true);
  const voteId = voteAddress!;

  useEffect(() => {
    if (
      context?.epochVoteRecordState &&
      !context.epochVoteRecordState.isLoading &&
      !context.epochVoteRecordState.error
    ) {
      if (
        context.epochVoteRecordState.data.size === 0 &&
        selectedEpoch.current
      ) {
        context.epochVoteRecordState.fetchData(IDEAL_RECORD_KEY);
      }
      if (selectedEpoch.epoch === undefined) {
        let voterRecordMap = context.epochVoteRecordState.data.get(voteId);
        if (!voterRecordMap) {
          context.epochVoteRecordState.fetchData(voteId);
        }
      } else if (!selectedEpoch.current && selectedEpoch.epoch !== undefined) {
        let voterRecordMap = context.epochVoteRecordState.data.get(voteId);
        if (!voterRecordMap || !voterRecordMap.has(selectedEpoch.epoch)) {
          context.epochVoteRecordState.fetchData(voteId, selectedEpoch.epoch);
        }
        let clusterRecordMap =
          context.epochVoteRecordState.data.get(IDEAL_RECORD_KEY);
        if (!clusterRecordMap || !clusterRecordMap.has(selectedEpoch.epoch)) {
          context.epochVoteRecordState.fetchData(
            IDEAL_RECORD_KEY,
            selectedEpoch.epoch
          );
        }
      }
    }
  }, [context, selectedEpoch]);

  const { resolvedEpoch, epochStartSlot, clusterData, voterData } =
    useMemo(() => {
      if (
        !context ||
        context.epochVoteRecordState.data.size === 0 ||
        !context.epochVoteRecordState.data.has(IDEAL_RECORD_KEY)
      )
        return {
          resolvedEpoch: undefined,
          clusterData: undefined,
          voterData: undefined,
          epochStartSlot: undefined
        };

      if (selectedEpoch.epoch !== undefined) {
        return {
          resolvedEpoch: selectedEpoch.epoch,
          epochStartSlot: selectedEpoch.epoch * 432_000,
          clusterData: context.epochVoteRecordState.data
            .get(IDEAL_RECORD_KEY)
            ?.get(selectedEpoch.epoch),
          voterData: context.epochVoteRecordState.data
            .get(voteId)
            ?.get(selectedEpoch.epoch)
        };
      }

      const idealRecord =
        context.epochVoteRecordState.data.get(IDEAL_RECORD_KEY)!;
      const [currentEpoch, data] = idealRecord.entries().next().value;
      return {
        resolvedEpoch: currentEpoch,
        clusterData: data as number[],
        epochStartSlot: currentEpoch * 432_000,
        voterData: context.epochVoteRecordState.data
          .get(voteId)
          ?.get(currentEpoch)
      };
    }, [context, selectedEpoch]);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    let epoch = Number.parseInt(event.target.value);
    navigate(`/vote-history/${voteId}/epoch/${epoch}`);
    setSelectedEpoch({
      current: false,
      epoch
    });
  };

  const missedCreditsOptions = useMemo(() => {
    return [
      {
        label: 'Show All Slots',
        value: 0
      },
      {
        label: 'Hide Slots without Missed Credits',
        value: 1
      }
    ];
  }, []);

  const epochOptions = useMemo(() => {
    if (
      !context ||
      context.epochVoteRecordState.data.size === 0 ||
      !context.epochVoteRecordState.data.has(IDEAL_RECORD_KEY)
    )
      return [
        {
          label: 'Current Epoch',
          value: undefined
        }
      ];

    const idealRecord =
      context.epochVoteRecordState.data.get(IDEAL_RECORD_KEY)!;
    // assume max is current
    let maxFetchedEpoch = 0;
    for (let blah of idealRecord) {
      maxFetchedEpoch = Math.max(maxFetchedEpoch, blah[0]);
    }

    let options = [];
    for (let i = Math.max(0, maxFetchedEpoch - 9); i <= maxFetchedEpoch; i++) {
      let label =
        i === maxFetchedEpoch
          ? `Current Epoch (${maxFetchedEpoch})`
          : `Epoch ${i}`;
      options.push({
        label,
        value: i
      });
    }
    options.reverse();
    return options;
  }, [context]);

  const CHUNK_SIZE = 1000;
  const bucketedOptimalData = useMemo(() => {
    if (!clusterData) return [];
    return splitArrayIntoChunks(clusterData, CHUNK_SIZE);
  }, [clusterData]);
  const bucketedVoterData = useMemo(() => {
    if (!voterData) return [];
    return splitArrayIntoChunks(voterData, CHUNK_SIZE);
  }, [voterData]);

  const computedTableColumns = useMemo(() => {
    return createEpochVoteRecordTableColumns(
      voteId,
      resolvedEpoch,
      epochStartSlot,
      CHUNK_SIZE,
      bucketedOptimalData
    );
  }, [bucketedOptimalData]);

  const bucketIndex = useMemo(() => {
    if (bucket === undefined) return 0;
    let parsedBucket = Number.parseInt(bucket);
    if (Number.isNaN(parsedBucket)) return 0;
    return parsedBucket;
  }, [bucket]);

  const computedBucketTableColumns = useMemo(() => {
    return createEpochVoteBucketRecordTableColumns(
      bucketIndex,
      epochStartSlot,
      bucketedOptimalData[bucketIndex]
    );
  }, [bucketedOptimalData, bucketIndex]);

  const bucketTable = useAdvanceTable({
    data: bucketedVoterData[bucketIndex] || [],
    columns: computedBucketTableColumns,
    pageSize: 1000,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSelectChange2 = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      let showAllSlots = Number.parseInt(event.target.value) === 0;
      if (showAllSlots) {
        bucketTable.resetColumnFilters();
      } else {
        bucketTable.setColumnFilters([
          {
            id: 'missed-credits',
            value: 'yo'
          }
        ]);
      }
      setShowAllSlots(showAllSlots);
    },
    [bucketTable]
  );

  const epochTable = useAdvanceTable({
    data: bucketedVoterData,
    columns: computedTableColumns,
    pageSize: 50,
    pagination: true,
    sortable: true,
    selection: false
  });

  if (!context) return <div>Loading...</div>;

  let { epochVoteRecordState } = context;

  if (selectedEpoch.current && epochVoteRecordState.isLoading)
    return <div>Loading...</div>;
  if (epochVoteRecordState.error)
    return <div>Error: {epochVoteRecordState.error}</div>;

  return (
    <div>
      <PageBreadcrumb items={voteRecordBreadcrumbItems} />
      <div className="mb-9">
        {bucket !== undefined ? (
          <Row className="align-items-start justify-content-between mb-4 g-3">
            <Col xs="auto">
              <h3>
                Missed Vote Credits in Epoch {epoch}, Bucket {bucket}/432
              </h3>
              <p className="text-body-tertiary lh-sm mb-0">
                Vote latencies breakdown
              </p>
            </Col>
          </Row>
        ) : (
          <Row className="align-items-start justify-content-between mb-4 g-3">
            <Col xs="auto">
              <h3>Vote Account Stats for {voteId}</h3>
              <p className="text-body-tertiary lh-sm mb-0">
                Vote latencies breakdown
              </p>
            </Col>
            <Col xs={12} sm={4}>
              <Form.Select
                size="sm"
                onChange={handleSelectChange}
                value={resolvedEpoch}
              >
                {epochOptions.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
        )}

        {resolvedEpoch !== undefined &&
          (bucket !== undefined ? (
            <EpochVoteBucketChart
              bucket={bucketIndex}
              epoch={resolvedEpoch}
              voteId={voteId}
              style={{ height: 270, width: '100%' }}
            />
          ) : (
            <EpochVotesChart
              epoch={resolvedEpoch}
              voteId={voteId}
              style={{ height: 270, width: '100%' }}
            />
          ))}

        {resolvedEpoch !== undefined && bucket === undefined && (
          <VoteLatencyOverview epoch={resolvedEpoch} voteId={voteId} />
        )}

        <Row className="justify-content-between align-items-center my-4 g-3">
          <Col xs="auto">
            <h3>Vote Record</h3>
            <p className="text-body-tertiary lh-sm mb-0">
              Vote latencies compared to optimal (minimum) latency
            </p>
          </Col>
          {bucket !== undefined && (
            <Col xs={12} sm={4}>
              <Form.Select
                size="sm"
                onChange={handleSelectChange2}
                value={showAllSlots ? 0 : 1}
              >
                {missedCreditsOptions.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </Col>
          )}
        </Row>

        {bucket !== undefined ? (
          <AdvanceTableProvider {...bucketTable}>
            <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
              <EpochVoteBucketRecordTable />
            </div>
          </AdvanceTableProvider>
        ) : (
          <AdvanceTableProvider {...epochTable}>
            <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
              <EpochVoteRecordTable />
            </div>
          </AdvanceTableProvider>
        )}
      </div>
    </div>
  );
};

export default VoteHistory;
